import React from "react";
import AboutUs from "../components/AboutUs"
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const WhyHk = (props) =>{
    return(
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <AboutUs {...props} fields={props?.data} />
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(WhyHk);

export const query = graphql`
fragment WhyHKFragment on MarkdownRemark {
     frontmatter {
      title
      content {
        title
        itemList{
            item
        }
      }
    }
  }
  query WhyHkPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "why-hk-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enWhyhk: markdownRemark(frontmatter: {templateKey: {eq: "en-why-hk"}}) {
  ...WhyHKFragment
  }
  deWhyhk: markdownRemark(frontmatter: {templateKey: {eq: "de-why-hk"}}) {
  ...WhyHKFragment
  }
  esWhyhk: markdownRemark(frontmatter: {templateKey: {eq: "es-why-hk"}}) {
  ...WhyHKFragment
  }
  frWhyhk: markdownRemark(frontmatter: {templateKey: {eq: "fr-why-hk"}}) {
  ...WhyHKFragment
  }
  heWhyhk: markdownRemark(frontmatter: {templateKey: {eq: "he-why-hk"}}) {
  ...WhyHKFragment
  }
  itWhyhk: markdownRemark(frontmatter: {templateKey: {eq: "it-why-hk"}}) {
  ...WhyHKFragment
  }
  jaWhyhk: markdownRemark(frontmatter: {templateKey: {eq: "ja-why-hk"}}) {
  ...WhyHKFragment
  }
  zhWhyhk: markdownRemark(frontmatter: {templateKey: {eq: "zh-why-hk"}}) {
  ...WhyHKFragment
  }
 }
`;